import React, { useState, useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import db from "../Firebase";

const Login = () => {
    const [phoneNo, setPhoneNo] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [generatedOtp, setGeneratedOtp] = useState(null);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [digits, setDigits] = useState({ d1: '', d2: '', d3: '', d4: '' });
    const inputRefs = useRef([]);
    const [cartItems, setCartItems] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchCart = () => {
        const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(storedCart);
        return storedCart;
    };

    const generateOtp = () => {
        return Math.floor(1000 + Math.random() * 9000).toString();
    };

    const sendOtp = async () => {
        const otp = generateOtp();
        setGeneratedOtp(otp);

        const message = `Your One Time Password (OTP) is ${otp}. Please enter this to complete the verification. In case of further assistance call us at 9873710709 / 18001236455.`;
        const apiKey = 'SsSIU4tDHEo-DWKzpHRKAWG8AtBIe3cMmkksUGCfPH';
        const sender = 'WHYTEF';
        const url = `https://api.textlocal.in/send/?apikey=${apiKey}&sender=${sender}&numbers=91${phoneNo}&message=${message}`;

        const myHeaders = new Headers();
        myHeaders.append("Cookie", "PHPSESSID=laf0mg6i82vkoikv376qhds344");

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        try {
            const response = await fetch(url, requestOptions);
            const result = await response.text();
            setIsOtpSent(true);
            setPhoneNo(''); // Clear the phone number input
        } catch (error) {
            console.error('Error sending OTP:', error);
        }
    };

    const fetchCustomerData = async (phoneNumber) => {
        try {
            const customersRef = db.collection('customers_data');
            const querySnapshot = await customersRef.where('customer_phone', '==', phoneNumber).get();

            if (!querySnapshot.empty) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error fetching customer data:', error);
            throw error;
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        if (otp === generatedOtp) {
            try {
                const customerExists = await fetchCustomerData(phoneNumber);
                if (customerExists) {
                    login(phoneNumber);
                    const cart = await fetchCart();
                    if(cart.length>0) {
                        navigate('/cart');
                    } else {
                        navigate('/');
                    }
                } else {
                    login(phoneNumber);
                    navigate('/customer_details');
                }
            } catch (error) {
                alert('An error occurred. Please try again.');
            }
        } else {
            alert('Incorrect OTP. Please try again.');
        }
    };

    const handlePhoneSubmit = (e) => {
        e.preventDefault();
        sendOtp();
    };

    const handlePhoneNoChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setPhoneNo(value);
            setPhoneNumber(value);
        }
    };

    const handleOtpChange = (e) => {
        const { name, value } = e.target;
        if (value.length > 1) return;

        if (!/^\d*$/.test(value)) {
            setErrorMessage('Please enter only numbers.');
            return;
        } else {
            setErrorMessage('');
        }

        setDigits((prev) => ({ ...prev, [name]: value }));

        if (value && inputRefs.current[parseInt(name.charAt(1))]) {
            inputRefs.current[parseInt(name.charAt(1))].focus();
        }

        const otp = Object.values({ ...digits, [name]: value }).join('');
        setOtp(otp);
    };

    const handleKeyDown = (e) => {
        const { name, value } = e.target;
        const index = parseInt(name.charAt(1));

        if (e.key === 'Backspace' && !value && index > 1) {
            inputRefs.current[index - 2].focus();
        }
    };

    return (
        <div>
            {!isOtpSent ? (
                <form onSubmit={handlePhoneSubmit}>
                    <div className="login-body">
                        <div className="container login-screen mt-3">
                            <div className="logo-login mt-5">
                                <center><img src="images/logo-icon.webp" style={{ height: '80px' }} alt="Logo"/></center>
                            </div>
                            <h4 className="theme-color text-center mt-5">Login / Sign up</h4>
                            <div className="login-mobile text-white text-center mt-4">
                                <label htmlFor="lname">+91</label>
                                <input
                                    className="login-input"
                                    type="tel"
                                    name="mobile"
                                    placeholder="Enter Mobile No."
                                    autoComplete='off'
                                    value={phoneNo}
                                    onChange={handlePhoneNoChange}
                                    required
                                />
                                <hr className="login-centered-hr" />
                            </div>
                            <div className="login-continue-btn mt-5">
                                <button type="submit" className="btn login-continue">Continue</button>
                            </div>
                            <br />
                            <div className="login-privacy text-center text-white">
                                <p style={{ color: '#ffff' }}>
                                    By Signing in you indicate that you have read and agreed
                                    to our <a href="/terms-of-use.html" style={{ color: '#d2ab67' }}>Term of Service</a> and 
                                    <a href="privacy-policy.html" style={{ color: "#d2ab67" }}>Privacy Policy</a>
                                </p>
                            </div>
                            <div class="login-contact-details text-white">
                                <div class="item"><i class="fa-regular fa-message"></i> Need Help? </div>
                                <div class="item">
                                <i class="fa-regular fa-envelope"></i>
                                <a href="mailto:care@whytefarms.com" class="contact-link">care@whytefarms.com</a>
                               </div>
                            <div class="item">
                                <i class="fa-solid fa-mobile-screen-button"></i>
                                <a href="tel:9873710709" class="contact-link">9873710709</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </form>
            ) : (
                <form onSubmit={handleOtpSubmit}>
                    <div className='login-body'>
                        <div className="container login-screen mt-3">
                            <div className="logo-login mt-5">
                                <center><img src="images/logo-icon.webp" style={{height:'80px'}} alt="Logo"/></center>
                                <h4 className="theme-color text-center mt-5">Enter One Time Password (OTP)</h4>
                                <p className="text-light text-center"><i>A four digit OTP has been sent to your mobile number</i></p>
                            </div>
                            <div className='container_otp'>
                                <div id='inputs' className='container_otp'>
                                    <input
                                        className='input-otp'
                                        type='tel'
                                        maxLength='1'
                                        name='d1'
                                        inputMode='numeric'
                                        pattern='[0-9]*'
                                        ref={(el) => (inputRefs.current[0] = el)}
                                        onChange={handleOtpChange}
                                        onKeyDown={handleKeyDown}
                                        autoComplete='off'
                                        autoFocus
                                    />
                                    <input
                                        className='input-otp'
                                        type='tel'
                                        maxLength='1'
                                        name='d2'
                                        inputMode='numeric'
                                        pattern='[0-9]*'
                                        ref={(el) => (inputRefs.current[1] = el)}
                                        onChange={handleOtpChange}
                                        onKeyDown={handleKeyDown}
                                        autoComplete='off'
                                    />
                                    <input
                                        className='input-otp'
                                        type='tel'
                                        maxLength='1'
                                        name='d3'
                                        inputMode='numeric'
                                        pattern='[0-9]*'
                                        ref={(el) => (inputRefs.current[2] = el)}
                                        onChange={handleOtpChange}
                                        onKeyDown={handleKeyDown}
                                        autoComplete='off'
                                    />
                                    <input
                                        className='input-otp'
                                        type='tel'
                                        maxLength='1'
                                        name='d4'
                                        inputMode='numeric'
                                        pattern='[0-9]*'
                                        ref={(el) => (inputRefs.current[3] = el)}
                                        onChange={handleOtpChange}
                                        onKeyDown={handleKeyDown}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            {errorMessage && (
                                <div style={{color:'#d2ab67'}} className="error-message  text-center mt-2 text-message">{errorMessage}</div>
                            )}
                            <div className="login-continue-btn mt-5">
                                <button type="submit" className="btn login-continue">VERIFY OTP</button>
                            </div>
                            <div className="login-privacy text-center text-white mt-4">
                                <p><span style={{color:'#d2ab67'}}>RESEND OTP?</span></p>
                            </div>
                            <div className="login-contact-details text-white">
                                <div className="item"><i className="fa-regular fa-message"></i> Need Help? </div>
                                <div className="item"><i className="fa-regular fa-envelope"></i>  <a href="mailto:care@whytefarms.com" class="contact-link">care@whytefarms.com</a></div>
                                <div className="item"><i className="fa-solid fa-mobile-screen-button"></i><a href="tel:9873710709" class="contact-link">9873710709</a></div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Login;