import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Account from './components/Account';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import AuthProvider from './context/AuthContext';
import ProductDetails from './components/ProductDetails';
import Cart from './components/Cart';
import CustomerDetails from './components/CustomerDetails';
import ProductCards from './components/ProductCards';
import AddCart from './components/AddCart';
import SubscriptionDetails from './components/SubscriptionDetails';
import Profile from './components/Profile';
import Otp from './components/Otp';
import CalendarPanel from './components/CalendarPanel';
import Breadcrumbs from './components/Breadcrumbs';
import EditableComponent from './components/EditableComponent';
import Ourstory from './components/Ourstory';
import Ourfarm from './components/Ourfarm';
import Faq from './components/faq';
import Product from './components/Product';
import ContactUs from './components/ContactUs';
import FailurePage from './components/FailurePage';
import SuccessPage from './components/SuccessPage';
import ProductModal from './components/Modal';
import UpdateSubscription from './components/UpdateSubscription';
import BookYourFarm from './components/BookYourFarm';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscription_details" element={<SubscriptionDetails />} />
          <Route path="/customer_details" element={<CustomerDetails />} />
          <Route path="/product_details/:id" element={<ProductDetails />} />
          <Route path="/account" element={<PrivateRoute element={<Account />} />} />
          <Route path="/productcards" element={<ProductCards />} />
          <Route path="/addcart" element={<AddCart />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/calendar" element={<CalendarPanel />} />
          <Route path="/navbar" element={<Navbar />} />
          <Route path="/edit" element={<EditableComponent />} />
          <Route path="/ourstory" element={<Ourstory />} />
          <Route path="/ourfarm" element={<Ourfarm />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/Product" element={<Product />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/failure" element={<FailurePage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/modal" element={<ProductModal />} />
          <Route path="/editsub" element={<UpdateSubscription />} />
          <Route path="/bookyourfarm" element={<BookYourFarm />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
