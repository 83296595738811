import React from 'react'
import Faq from './faq';
import { useNavigate, Link } from 'react-router-dom';

function Footer() {

  const navigate = useNavigate();

  const loadFaq = () => {
    navigate("/faq");
  }

  const loadContactUs = () => {
    navigate("/contactus");
  }

  const navigateToOurFarm = () => {
    navigate("/ourfarm");
  }

  const navigateToOurStory = () => {
    navigate("/ourstory");
  }

  return (
    <>
        <footer>
      <div class="container">
        <div class="row mt-3">
          <div class="col-md-3 col-lg-3 col-sm-12">
            <div class="logo-c=section footer-logo p-3 mt-5">
              <img src="../images/logo-icon.webp" style={{height:"150px"}}/>
            </div>
          </div>
         <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 footer-menu-responsive p-3">
          <div class="">
            <div class="footer-menu-item text-white mt-5">
              <ul>
                <li style={{borderBottom:'0px' , cursor:'pointer'}} onClick={navigateToOurStory}>OUR STORY</li>
                <li style={{borderBottom:'0px', cursor:'pointer'}} onClick={navigateToOurFarm}>OUR FARM</li>
                {/* <li style={{borderBottom:'0px'}}>OUR COWS</li> */}
              </ul>
            </div>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6 ">
            <div class="footer-menu-item text-white mt-5">
              <ul>
                {/* <li style={{borderBottom:'0px'}}>GALLERY</li> */}
                <li style={{borderBottom:'0px'}}><a onClick={loadFaq}>FAQS</a></li>
                <li style={{borderBottom:'0px'}}><a onClick={loadContactUs}>CONTACT US</a></li>
              </ul>
            </div>
            </div>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 p-4">
            <div class="footer-menu-img">
            <img src="../images/play-store.png"/>
              <img src="../images/apple-store.png"/>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row mt-3">
          <div class="col-md-8 col-lg-8 col-sm-12 text-white text-center footer-term-content">
            <p style={{color:"white"}}><a href="/terms-of-use.html" style={{ color: '#fff' }}>Terms of Use</a> | <a href="privacy-policy.html" style={{ color: "#fff" }}>Privacy Policy </a>| <a href="refund.html" style={{ color: "#fff" }}>Return Policy</a> | <a href="/ship.html" style={{ color: '#fff' }}>Ship</a><br/>
              © 2021 Whyte Farms LLP, All rights reserved.</p>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12">
            <div class="footer-icons">
              <a href="https://www.facebook.com/thewhytefarms/" class="social-icon"><i
                  class="fab fa-facebook-f"></i></a>
              <a href="https://x.com/whytefarms?lang=en" class="social-icon"><i class="fab fa-twitter"></i></a>
              <a href="https://www.instagram.com/thewhytefarms/" class="social-icon"><i
                  class="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>

      </div>
    </footer>
    </>
  )
}

export default Footer
